<template>
  <KTCard :page="page">
    <template #body>
      <KTTable
        :fields="autoconsumo_columns"
        :items="autoconsumo.data"
        :primaryKey="autoconsumo.primaryKey"
        :sortBy="autoconsumo.sortBy"
      >
        <template #cell(_actions)="{ item }">
          <KTViewMiniButton
            :to="getEditRoute(item.idpip_produto)"
            v-if="finalizado"
          />
          <KTEditMiniButton :to="getEditRoute(item.idpip_produto)" v-else />
        </template>

        <template #custom-foot>
          <tr class="bg-primary text-white" v-if="autoconsumo.foot_data[0]">
            <td></td>
            <td></td>
            <td>Total</td>
            <td></td>
            <td class="text-center">{{ simbolo_moeda }}</td>
            <template v-for="(ano, index) in anos">
              <td :key="'td-anos-2-' + index"></td>
              <td :key="'td-anos-3-' + index" class="text-right">
                {{
                  formatCurrency(
                    autoconsumo.foot_data[0][
                      "valor_autoconsumo_total_ano_" + ano
                    ]
                  )
                }}
              </td>
            </template>
          </tr>
        </template>
      </KTTable>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipAutoconsumoList",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-user-circle",
        title: "Autoconsumo",
        description: `O autoconsumo corresponde ao uso de parte da produção
          para o consumo doméstico da família. Para efeito de cálculos e
          análise financeira do projeto, os produtos consumidos são
          considerados como receita, da mesma forma que a parcela da produção
          que é comercializada. Cadastre o percentual de autoconsumo de cada
          produto. Para isso, use o botão de editar localizado abaixo de AÇÕES.`
      },
      autoconsumo: {
        data: [],
        foot_data: [],
        primaryKey: "idpip_produto",
        sortBy: "numero_item"
      },
      simbolo_moeda: "",
      anos: 0,
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/autoconsumo`;
    },
    manual() {
      return {
        session: "autoconsumo",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    autoconsumo_columns() {
      let columns = [
        {
          key: "_actions",
          label: "Ações",
          sortable: false,
          tdClass: "text-center"
        },
        {
          key: "numero_item",
          label: "Item",
          sortable: true
        },
        {
          key: "descricao",
          label: "Descrição",
          sortable: true
        },
        {
          key: "nome_unidade_medida",
          label: "Unidade de Medida",
          sortable: true,
          tdClass: "text-center"
        },
        {
          key: "simbolo_moeda",
          label: "Moeda",
          sortable: true,
          tdClass: "text-center"
        }
      ];

      for (let ano = 1; ano <= this.anos; ano++) {
        columns.push({
          key: "quantidade_total_autoconsumo_ano_" + ano,
          label: "Quantidade Ano " + ano,
          sortable: true,
          tdClass: "text-right",
          formatter: "number"
        });
        columns.push({
          key: "valor_autoconsumo_total_ano_" + ano,
          label: "Valor Total Ano " + ano,
          sortable: true,
          tdClass: "text-right",
          formatter: "currency"
        });
      }

      return columns;
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.autoconsumo.data = res[R_GETLIST].autoconsumo;
        this.autoconsumo.foot_data = res[R_GETLIST].autoconsumo_total;
        this.simbolo_moeda = res[R_GETLIST].simbolo_moeda;
        this.anos = res[R_GETLIST].anos;
        this.tipo_plano_investimento = res[R_GETLIST].tipo_plano_investimento;
        this.finalizado = res[R_GETLIST].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    getEditRoute(id) {
      return {
        name: "pip_autoconsumo_edit",
        params: {
          idplano_investimento: this.idplano_investimento,
          idpip_produto: id
        }
      };
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
